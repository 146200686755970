.container-fluid .jumbotron, .container .jumbotron {
	border-radius: 6px;
	padding-left: 15px;
	padding-right: 15px;
  }
  
  @media screen and (min-width: 768px)
  {
  .container-fluid .jumbotron, .container .jumbotron {
	  padding-left: 60px;
	  padding-right: 60px;
  }
  }
  
  @media screen and (min-width: 768px)
  {
  .jumbotron {
	  padding-bottom: 48px;
	  padding-top: 48px;
  }
  }
  
  .jumbotron {
	background-color: #eee;
	padding-left: 60px;
	padding-right: 60px;
	margin-bottom: 30px;
	padding-bottom: 30px;
	padding-top: 30px;
  }
.styleicons {
    color: #555555;
    font-size: 20px;
    cursor: pointer;
    margin-right: 15px;
}
.fieldComments {
  color: #ff6a00;
  font-size: 10pt;
  font-style: italic;
}
.linkText {
  font-size: 10pt;
  color: #666666;
}
.error-message-cms {
	color: red;
	font-size: 12px;
	font-weight: 600;
	margin-top: 4px;
}
.notesText {
  font-size: 10pt;
  color: #787878;
  font-style: italic;
}
.smallForm {
  /*background-color: #EEE;*/
  border-radius: 10px;
  padding: 20px;
  background-image: linear-gradient(to bottom, #EEEEEE , #F7F7F7);
}
.react-date-picker__wrapper {
  border: 'none'
}
select-multi > div:nth-child(3) > div:last-child > div:first-child > svg {
  opacity: 0;
}
.select-multi > div:nth-child(3) > div:last-child > div:last-child > svg {
  opacity: 0;
}
/* ---- LOADING ---------------------------------------- */
.loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
/* Transparent Overlay */
.loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.75);
}
  
.loading:not(:required):after {
    content: '';
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 1500ms infinite linear;
    -moz-animation: spinner 1500ms infinite linear;
    -ms-animation: spinner 1500ms infinite linear;
    -o-animation: spinner 1500ms infinite linear;
    animation: spinner 1500ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(180, 180, 180, 0.75) 1.5em 0 0 0, rgba(180, 180, 180, 0.75) 1.1em 1.1em 0 0, rgba(180, 180, 180, 0.75) 0 1.5em 0 0, rgba(180, 180, 180, 0.75) -1.1em 1.1em 0 0, rgba(180, 180, 180, 0.5) -1.5em 0 0 0, rgba(180, 180, 180, 0.5) -1.1em -1.1em 0 0, rgba(180, 180, 180, 0.75) 0 -1.5em 0 0, rgba(180, 180, 180, 0.75) 1.1em -1.1em 0 0;
    box-shadow: rgba(180, 180, 180, 0.75) 1.5em 0 0 0, rgba(180, 180, 180, 0.75) 1.1em 1.1em 0 0, rgba(180, 180, 180, 0.75) 0 1.5em 0 0, rgba(180, 180, 180, 0.75) -1.1em 1.1em 0 0, rgba(180, 180, 180, 0.75) -1.5em 0 0 0, rgba(180, 180, 180, 0.75) -1.1em -1.1em 0 0, rgba(180, 180, 180, 0.75) 0 -1.5em 0 0, rgba(180, 180, 180, 0.75) 1.1em -1.1em 0 0;
}
@-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}
@-moz-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}
@-o-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}
@keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}

/* ---- Card Progress ---------------------------------------- */

.card-progress {
    padding: 0 !important;
    border-radius: 14px !important;
}
.card-progress.red {
    background-image: linear-gradient(0deg,rgba(238,108,119,1) 0%, rgba(255,255,255,1) 100%) !important;
}
.card-progress.blue {
    background-image: linear-gradient(0deg,rgba(122,227,197,1) 0%, rgba(255,255,255,1) 100%) !important;
}
.card-progress.green {
    background-image: linear-gradient(0deg,rgba(145,227,122,1) 0%, rgba(255,255,255,1) 100%) !important;
}
.card-progress .card-header {
    background: none;
    border-bottom: none;
}
.card-progress .card-body {
    text-align: center;
}
.progress {
	width: 150px;
	height: 150px !important;
	line-height: 150px;
	background: none !important;
	margin: 0 auto 15px;
	box-shadow: none;
	position: relative;
}
.progress:after {
	content: "";
	width: 100%;
	height: 100%;
	border-radius: 50%;
	border: 12px solid transparent;
	position: absolute;
	top: 0;
	left: 0;
}
.progress > span {
	width: 50%;
	height: 100%;
	overflow: hidden;
	position: absolute;
	top: 0;
	z-index: 1;
}
.progress .progress-left {
	left: 0;
}
.progress .progress-bar {
	width: 100%;
	height: 100%;
	background: none;
	border-width: 12px;
	border-style: solid;
	position: absolute;
	top: 0;
}
.progress .progress-left .progress-bar {
	left: 100%;
	border-top-right-radius: 80px;
	border-bottom-right-radius: 80px;
	border-left: 0;
	-webkit-transform-origin: center left;
	transform-origin: center left;
}
.progress .progress-right {
	right: 0;
}
.progress .progress-right .progress-bar {
	left: -100%;
	border-top-left-radius: 80px;
	border-bottom-left-radius: 80px;
	border-right: 0;
	-webkit-transform-origin: center right;
	transform-origin: center right;
	animation: loading-1 1.8s linear forwards;
}
.progress .progress-value {
	width: 90%;
	height: 90%;
	border-radius: 50%;
	font-size: 24px;
	color: #000;
	line-height: 135px;
	text-align: center;
	position: absolute;
	top: 5%;
	left: 5%;
}
.progress.progress10 .progress-right .progress-bar {
	animation: loading-20 1.5s linear forwards;
}
.progress.progress20 .progress-right .progress-bar {
	animation: loading-40 1.5s linear forwards;
}
.progress.progress30 .progress-right .progress-bar {
	animation: loading-60 1.5s linear forwards;
}
.progress.progress40 .progress-right .progress-bar {
	animation: loading-80 1.5s linear forwards;
}
.progress.progress50 .progress-right .progress-bar {
	animation: loading-100 1.5s linear forwards;
}
.progress.progress60 .progress-right .progress-bar {
	animation: loading-100 1.5s linear forwards;
}
.progress.progress60 .progress-left .progress-bar {
	animation: loading-20 1.5s linear forwards 1.5s;
}
.progress.progress70 .progress-right .progress-bar {
	animation: loading-100 1.5s linear forwards;
}
.progress.progress70 .progress-left .progress-bar {
	animation: loading-40 1.5s linear forwards 1.5s;
}
.progress.progress80 .progress-right .progress-bar {
	animation: loading-100 1.5s linear forwards;
}
.progress.progress80 .progress-left .progress-bar {
	animation: loading-60 1.5s linear forwards 1.5s;
}
.progress.progress90 .progress-right .progress-bar {
	animation: loading-100 1.5s linear forwards;
}
.progress.progress90 .progress-left .progress-bar {
	animation: loading-80 1.5s linear forwards 1.5s;
}
.progress.progress100 .progress-right .progress-bar {
	animation: loading-100 1.5s linear forwards;
}
.progress.progress100 .progress-left .progress-bar {
	animation: loading-100 1.5s linear forwards 1.5s;
}

@keyframes loading-20 {
	0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
	100% { -webkit-transform: rotate(36deg); transform: rotate(36deg); }
}
@keyframes loading-40 {
	0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
	100% { -webkit-transform: rotate(72deg); transform: rotate(72deg); }
}
@keyframes loading-60 {
	0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
	100% { -webkit-transform: rotate(108deg); transform: rotate(108deg); }
}
@keyframes loading-80 {
	0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
	100% { -webkit-transform: rotate(144deg); transform: rotate(144deg); }
}
@keyframes loading-100 {
	0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
	100% { -webkit-transform: rotate(180deg); transform: rotate(180deg); }
}
/* Fonts */

@font-face {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 100;
	src: url(https://fonts.gstatic.com/s/raleway/v22/1Ptug8zYS_SKggPNyC0ITw.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 200;
	src: url(https://fonts.gstatic.com/s/raleway/v22/1Ptug8zYS_SKggPNyC0ITw.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 300;
	src: url(https://fonts.gstatic.com/s/raleway/v22/1Ptug8zYS_SKggPNyC0ITw.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/raleway/v22/1Ptug8zYS_SKggPNyC0ITw.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 500;
	src: url(https://fonts.gstatic.com/s/raleway/v22/1Ptug8zYS_SKggPNyC0ITw.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 600;
	src: url(https://fonts.gstatic.com/s/raleway/v22/1Ptug8zYS_SKggPNyC0ITw.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/raleway/v22/1Ptug8zYS_SKggPNyC0ITw.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 800;
	src: url(https://fonts.gstatic.com/s/raleway/v22/1Ptug8zYS_SKggPNyC0ITw.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 900;
	src: url(https://fonts.gstatic.com/s/raleway/v22/1Ptug8zYS_SKggPNyC0ITw.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Merriweather";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/merriweather/v30/u-440qyriQwlOrhSvowK_l5-fCZM.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofINeaB.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofINeaB.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofINeaB.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofINeaB.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v25/XRXV3I6Li01BKofINeaB.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Body */

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: "Raleway";
	font-weight: 500;
	font-size: 14px;
	color: #040517;
}

section {
	display: inline;
}

b,
strong {
	font-weight: 700 !important;
}

p {
	white-space: pre-wrap;
}

code {
	font-family: "Courier New";
}
a { text-decoration:none; }
a.link, a.visited, a.active, a.hover { text-decoration:none; color: #e96565; }


a.text-primary:focus,
a.text-primary:hover {
	color: #e96565 !important;
}

a.text-white:focus,
a.text-white:hover {
	color: #e96565 !important;
}

.text-link {
	cursor: pointer;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 600 !important;
	line-height: 1;
}

.h1,
h1 {
	font-size: 48px;
	font-weight: 400 !important;
}

.h2,
h2 {
	font-weight: 500 !important;
}

.h5,
h5 {
	line-height: 1.5;
}

#root > .main {
	min-height: 200px;
}

.small,
small {
	font-size: 70%;
}

.mt--4 {
	margin-top: -1.5rem;
}
.mt--5 {
	margin-top: 3rem;
}
.w250 {
	max-width: 250px;
}

.wh-120 {
	width: 120px;
	height: 120px;
	border-radius: 50%;
	object-fit: cover;
	object-position: center;
}

.wh-180 {
	width: 180px;
	height: 180px;
	border-radius: 50%;
	object-fit: cover;
	object-position: center;
}

.mnh-120 {
	min-height: 120px;
}

.mxh-300 {
	max-height: 300px;
}

.mxw-250 {
	max-width: 250px;
}

.mxw {
	width: 100%;
	max-width: 100%;
	object-fit: cover;
}

.w-40 {
	width: 40%;
	max-width: 100%;
	object-fit: cover;
}

.h-250 {
	height: 250px;
}

.h-300 {
	height: 300px;
}

.h-350 {
	height: 350px;
}

.br-4 {
	border-radius: 4px;
}

.br-8 {
	border-radius: 8px;
}

.clamp-2 {
	white-space: normal;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.clamp-3 {
	white-space: normal;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.error-message {
	display: block;
	color: red;
	font-size: 12px;
	font-weight: 600;
	right: 0;
	float: right;
	margin-top: 4px;
	animation: blink 2.5s linear infinite alternate 2.5s;
}

@keyframes blink {
	80% {
		color: red;
	}
	90% {
		color: white;
	}
	100% {
		color: red;
	}
}

.form-error .error-message {
	display: inline-block;
}

/* Primary Color */

.bg-primary {
	background-color: #e96565 !important;
}

.bg-light {
	background-color: #f2f2f2 !important;
}

.bg-dark {
	background-color: #040517 !important;
}

.bg-accent {
	background-color: #3d0101 !important;
}

.text-primary {
	color: #e96565 !important;
}

.text-red {
	color: #e96565 !important;
}

.text-strong {
	font-weight: 600;
}

.text-800 {
	font-weight: 800 !important;
}

.btn {
	font-weight: 600;
}

.btn-primary {
	background-color: #e96565 !important;
	border-color: #e96565 !important;
}

.btn-primary:hover,
.btn-primary.focus,
.btn-primary:focus {
	background-color: #e96565 !important;
	border-color: #e96565 !important;
	box-shadow: none !important;
}

.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary[disabled] {
	background-color: #ccc !important;
	border-color: #ccc !important;
	color: #999 !important;
	pointer-events: none;
}

.btn-outline-primary {
	border-color: #e96565 !important;
	color: #e96565 !important;
}

.btn-outline-primary:hover {
	color: #fff !important;
	background-color: #e96565 !important;
	border-color: #e96565 !important;
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
	box-shadow: 0 0 0 0.2rem rgb(200 70 48 / 50%) !important;
}

.btn-gray {
	color: #ccc!important;
}
.btn-gray:hover {
	text-decoration: none;
}
.btn-gray:focus {
	outline: none;
	box-shadow: none;
	text-decoration: none;
}

.nav-pills a {
	font-weight: 600;
	color: #3d0101 !important;
}
.nav-pills .nav-link {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
	background-color: #3d0101 !important;
	color: #fff !important;
	border-radius: 50px;
}

.home .nav-pills .nav-link {
	font-weight: 600;
	color: #3d0101 !important;
	border-radius: 0;
	border-bottom: none;
	background-color: transparent !important;
	padding: 0.5rem 0.5rem;
	margin: 0 5px;
}

.home .nav-pills .nav-link.active,
.home .nav-pills .show > .nav-link {
	border-bottom: 3px solid #3d0101;
	background-color: transparent;
}

.nav-tabs {
	margin-bottom: 10px;
	border: none;
}

.nav-tabs .nav-link {
	color: #3d0101;
	font-weight: 600;
	border-bottom: 4px solid #f2f2f2;
	border-radius: 0;
	border-top: none;
	border-left: none;
	border-right: none;
	padding: 0.5rem 2rem;
}

.nav-tabs .nav-link:hover {
	border-top-color: none;
	border-left-color: none;
	border-right-color: none;
	color: #3d0101;
	border-bottom: 4px solid #3d0101;
}

.nav-tabs .nav-link.active {
	color: #3d0101;
	border: none;
	border-bottom: 4px solid #3d0101;
}

/* Header */

.alert {
	margin-bottom: 25px;
	padding-right: 0;
	border-radius: 0;
	border: none;
}

.alert .close {
	top: -14px;
	font-size: 40px;
	font-weight: 300;
}

.alert-warning,
.alert-warning .alert-link {
	color: #3d0101;
}

.alert-info {
	background-color: #f2f2f2;
}

.alert-info .alert-link {
	color: #e96565;
}

.alert-info .alert-link:hover {
	color: #ee3535;
}

.navbar-light .navbar-toggler {
	padding: 0;
	border-color: transparent;
}

.navbar-dark .navbar-toggler {
	padding: 0;
	border-color: transparent;
}

.navbar-toggler-icon {
	width: 2.5em;
	height: 2.5em;
}

.navbar-brand {
	padding-top: 0;
	padding-bottom: 0;
	font-weight: 700;
}

.navbar-dark .navbar-nav .nav-link {
	color: rgba(255, 255, 255, 1);
}

.navbar-language {	
	flex-direction: row;
	justify-content: right;
	column-gap: 10px;
}

.nav-profile img {
	width: 35px;
	height: 35px;
	object-fit: cover;
	object-position: center;
	/*float: right; */
}

.nav-profile label {
	float: left;
	text-align: right;
	line-height: normal;
	margin: 0 15px 0 0;
	cursor: pointer;
	text-wrap: nowrap;
}

.nav-profile label span {
	font-size: 14px;
	font-weight: 600;
}

.nav-language {
	cursor: pointer;
	opacity: 0.5;
	display: flex;
	align-items: center;
}

.nav-language:hover,
.nav-language.active {
	opacity: 1;
}

.nav-language:first-child {
	margin-left: 15px;
}

.nav-language:last-child {
	margin-right: 15px;
}

.nav-language img {
	width: 20px;
	height: 20px;
	object-fit: cover;
	object-position: center;
	float: left;
}

.nav-language label {
	font-weight: 600;
	float: right;
	margin-left: 5px;
	margin-bottom: 0;
	cursor: pointer;
}

.nav-cart {
	position: relative;
	font-size: 22px;
	margin-right: 15px;
	padding: 0 20px !important;
	border-left: 1px solid #ef8585;
	border-right: 1px solid #ef8585;
}

.nav-cart span {
	position: absolute;
	top: -3px;
	right: 6px;
	font-size: 12px;
	width: 20px;
	height: 20px;
	line-height: 20px;
	background: #D82B2A;
	border-radius: 50px;
	text-align: center;
	color: #fff;
}

.nav-cart span.empty {
	background: transparent;
}

.menu-guest .nav-language label {
	color: #343a40;
}

.menu-guest .nav-language.active img {
	border: 2px solid #343a40;
}

.menu-member .nav-language label {
	color: #fff;
}

.menu-member .nav-language.active img {
	border: 2px solid #fff;
}

.nav-notification {
	width: 35px;
	height: 35px;
	font-size: 26px;
	padding: 5px 0 0;
}

.nav-secondary .nav-link {
	padding-right: 25px !important;
	padding-left: 25px !important;
	font-size: 14px;
	font-weight: 700;
}

.nav-secondary .nav-link:last-child {
	padding-right: 0 !important;
}

.nav-mobile {
	display: none;
}

/* Separator */
.separator {
	margin: 75px auto 60px;
	text-align: center;
	width: 180px;
	border-top: 2px solid #dddd;
}
.separator.line {
	border-top: 2px solid #666;
	margin: 30px 0 30px;
	width: 100%;
	height: 2px;
}
.separator .icon {
	width: 45px;
	margin: 0 auto;
	background: #fff;
	margin-top: -14px;
}
.separator .icon svg {
	font-size: 24px;
	color: #fff;
	background: #040517;
	border-radius: 50px;
	width: 24px;
	height: 24px;
	padding: 5px;
}

/* Main Banner */

.banner-home {
	border-radius: 8px;
	background: url("../img/main-banner.jpg") no-repeat;
	background-size: cover;
}

.banner-second {
	border-radius: 8px;
	background: url("../img/second-banner.jpg") no-repeat;
	background-size: cover;
}

.banner-member {
	border-radius: 8px;
	background-color: #ffe1e1;
}

/* Featured */

.featured-home .agenda {
	background: url("../img/bg-featured-agenda.jpg") no-repeat;
	background-size: cover;
	background-position: bottom right;
	border-radius: 8px;
	max-height: 800px;
	overflow: hidden;
	position: relative;
}
.featured-home .agenda-more {
	background: url("../img/bg-featured-more.png") no-repeat;
	background-size: cover;
	background-position: bottom right;
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 220px;
	text-align: center;
}

.row.equal-cols {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.row.equal-cols:before,
.row.equal-cols:after {
	display: block;
}

.row.equal-cols > [class*="col-"] {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.row.equal-cols > [class*="col-"] > * {
	-webkit-flex: 1 1 auto;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
}

/* Agenda */

.agenda-item h5 {
	line-height: 1.5;
}

/* Label */

.label-rounded {
	background: #040517;
	color: #fff;
	border-radius: 50px;
	padding: 10px 25px;
	min-width: 150px;
	text-align: center;
}

.label-rounded-2 {
	background: #040517;
	color: #fff;
	border-radius: 50px;
	padding: 4px 18px;
	min-width: 100px;
	font-size: 14px;
	text-align: center;
}

.label-expired {
	padding: 5px 10px;
	color: #e96565;
	background: #f2f2f2;
	font-size: 12px;
	width: auto;
	display: inline;
	position: absolute;
	top: 7px;
	left: 7px;
}

/* Card */

.card {
	transition: 0.1s ease-in-out;
	border: none;
	border-radius: 0;
	box-shadow: none;
	background: none;
}

.card:hover {
	box-shadow: 3px 3px 10px 3px #dbdbdb;
}

.card-img,
.card-img-top {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.card-pop {
	box-shadow: 5px 5px 15px 5px #dbdbdb;
	padding: 0;
}

.card-title {
	min-height: 33px;
	margin-bottom: 10px;
	font-size: 16px !important;
	line-height: 1.5 !important;
	white-space: normal;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.card-invoice {
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0;
	font-family: 'Nunito';
}

.card-invoice .card-header {
	border: none;
	background: none;
	padding: 0;
}

.card-invoice .card-header .table-right td {
	padding-bottom: 10px;
}

.card-invoice .card-header .table-right tr:last-child td {
	padding-bottom: 0;
}

.card-invoice .card-body {
	border: none;
	padding: 0;
}

.card-invoice .card-body th {
	padding-top: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid #000;
}

.card-invoice .card-body td {
	vertical-align: top;
	padding-top: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid #ccc;
}

.card-invoice .card-body tfoot td {
	background: none;
	border-bottom: none;
}

.card-invoice .card-body p {
	margin-bottom: 0;
}

.card-invoice .card-body small {
	font-size: 10px;
}

.card-invoice .card-footer {
	background: none;
}

.card-invoice .card-footer .total {
	border-top: 1px solid #000;
}

.card-invoice h1 {
	font-size: 48px;
	font-weight: 700;
}

/*
.card-faq .card {
	margin-bottom: 15px !important;
	border-radius: 8px !important;
}

.card-faq .card .card-header {
	font-weight: 600;
	cursor: pointer;
}

.card-faq.accordion > .card:not(:last-of-type) {
	border-bottom: 1px solid #ccc;
}

.card-faq.accordion > .card .card-body {
	background: #fff;
}

.card-faq.accordion > .card .card-body ol {
	padding-inline-start: 20px;
}
*/
a .card {
	color: #040517;
}

a .card:hover * {
	text-decoration: none;
}

.course-block {
	padding: 15px;
	/* background: white; */
	/* box-shadow: 0px 0px 5px 2px #dbdbdb;  */
	box-shadow: 0px 0px 0px 0px !important;
	border-radius: 8px;
	height: 100%;
	background-color: transparent !important;

}

.course-block .card-body {
	padding: 0;
}

.course-block .card-title {
	min-height: 48px;
	margin: 15px 0 0;
}

.course-block .card-img-top,
.library-block .card-img-top {
	border-radius: 8px;
	height: 200px;
	object-fit: contain;
	object-position: center;
	/* background-color: #fff; */
	background-color: transparent !important;
}

.library-block {
	padding: 15px;
	border-radius: 8px;
	height: 100%;
	background-color: transparent !important;
	box-shadow: 0px 0px 0px 0px !important;
}

.course-block:hover, 
.library-block:hover {
	background-color: #fff !important;
	box-shadow: 0px 0px 5px 2px #dbdbdb; 
}

.library-block .card-body {
	padding: 0;
}

.library-block .card-title {
	margin: 15px 0 0;
}

.course-block .card-img-top img,
.library-block .card-img-top img {
	border-radius: 8px;
	background-color: #fff !important;
}

.course-list,
.library-list {
	margin-bottom: 30px;
	color: #040517;
}

.course-list img,
.library-list img {
	height: 145px;
	object-fit: contain;
	object-position: center;
	background-color: #fff;
}

.course-list:hover h5,
.library-list:hover h5 {
	color: #e96565;
}

.purchased .course-list img,
.purchased .library-list img {
	height: 100px;
}

.ticket-list {
	margin-top: 30px;
	color: #040517;
}

/* List */

.list-title {
	font-size: 16px !important;
	line-height: 1.5 !important;
	white-space: normal;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.clamp-3 {
	white-space: normal;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.clamp-4 {
	white-space: normal;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

/* Input */
/*
.input-link {
	max-width: 250px;
	text-align: left;
}
.input-link input {
	font-size: 22px;
	text-align: center;
}

.input-group input {
	border: 2px solid #3d0101;
	border-top-left-radius: 50px;
	border-bottom-left-radius: 50px;
	border-right: none;
	font-size: 14px;
	font-weight: 600;
	padding-left: 15px;
}

.input-group .input-group-text {
	border: 2px solid #3d0101;
	border-top-right-radius: 50px;
	border-bottom-right-radius: 50px;
	border-left: none;
	background: transparent;
	cursor: pointer;
	color: #3d0101;
}
*/
/* Button */

.btn-light {
	background-color: transparent !important;
	border-color: transparent !important;
	color: #343a40 !important;
}

.btn-light:active,
.btn-light:focus,
.btn-light:hover {
	background-color: transparent !important;
	border-color: transparent !important;
	outline: none;
	box-shadow: none !important;
	color: #e96565 !important;
}

.btn-google {
	width: 100%;
	justify-content: center;
	margin-bottom: 1rem;
	color: #fff !important;
	background-color: #e96565 !important;
	box-shadow: none !important;
	font-family: inherit !important;
	font-weight: 600 !important;
	font-size: 16px !important;
	height: 38px;
	border-radius: 8px !important;
}

.btn-google > span {
	padding: 0;
}

.btn-google img {
	width: 30px;
	/*position: absolute;*/
	margin-right: 10px;
	left: 24px;
	top: 24px;
	border-radius: 8px;
}

.btn-google > div {
	display: none !important;
}

.btn-logout {
	padding: 0 !important;
	width: 100%;
	height: auto;
	color: #fff !important;
	background-color: transparent !important;
	box-shadow: none !important;
}

.btn-logout > div {
	display: none !important;
}

.btn-logout > span {
	padding: 0 !important;
	font-family: "Raleway" !important;
	font-size: 14px !important;
	font-weight: 600 !important;
}

.btn-icon-circle {
	border-radius: 50% !important;
	width: 38px;
	height: 38px;
	padding: 0 !important;
	line-height: 34px !important;
}

.btn-calendar {
	width: 100%;
	max-width: 250px;
	font-size: 16px;
	font-weight: 400;
}

.btn-social {
	background-color: transparent !important;
	border-color: transparent !important;
	color: #fff !important;
}

.btn-social:active,
.btn-social:focus,
.btn-social:hover {
	background-color: transparent !important;
	border-color: transparent !important;
	outline: none;
	box-shadow: none !important;
	color: #e96565 !important;
}

.link-more {
	color: #040517;
	font-size: 18px;
	font-weight: 600;
	cursor: pointer;
}

.link-more:hover {
	color: #e96565;
}

.link-more-2 {
	color: #040517;
	font-size: 14px;
	font-weight: 600;
}

.link-more-2:hover {
	color: #e96565;
}

.link-detail {
	color: #040517;
	font-weight: 600;
}

.link-detail:hover {
	color: #e96565;
}

/* Exam */

.exam-result {
	background-repeat: no-repeat;
	background-position: bottom;
	background-size: contain;
}

.exam-result table th {
	min-width: 200px;
	height: 40px;
}

.exam-result table td {
	height: 60px;
	font-size: 18px;
}

.exam-result table td.correct {
	background-color: #dff0d8;
}

.exam-result table td.incorrect {
	background-color: #f2dede;
}

.exam-result .img-graduation {
	display: block;
	margin: 0 auto 15px;
}

#result-table {
	display: none;
}

/* Information */

.sticky-top {
	top: 0;
}

.sidebar-tab.sticky-top {
	top: 85px;
	z-index: 0;
}

.information {
	border-radius: 8px;
	background: #f2f2f2;
}

.information p:last-child {
	margin-bottom: 0;
}

.library-usage .list-group-item {
	border: 2px solid rgba(250, 250, 250, 0.5);
	background: #040517;
	color: white;
}

.library-usage .list-group-item:first-child {
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
}

.library-usage .list-group-item:last-child {
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}

.library-usage .list-group-item + .list-group-item {
	border-top-width: 2px;
	border-bottom-width: 2px;
}

.library-usage .list-group-item h5 {
	line-height: normal;
}

.library-usage .list-group-item p:last-child {
	margin-bottom: 0;
}

/* Modal */

.modal-fullscreen {
	width: 100vw;
	max-width: none;
	height: 100%;
	margin: 0;
}

.modal-fullscreen .modal-content {
	height: 100%;
	border: 0;
	border-radius: 0;
}

.modal-fullscreen .modal-header {
	border-radius: 0;
}

.modal-fullscreen .modal-body {
	overflow-y: auto;
}

.modal-fullscreen .modal-footer {
	border-radius: 0;
}

.modal iframe {
	border: none;
}

/* Accordion */

/*
.accordion .card {
	margin-bottom: 0;
	padding: 0;
}
.accordion > .card {
	border: 1px solid #ccc;
}
.accordion > .card > .card-header {
	border-bottom: none;
	border-radius: 0;
	margin-bottom: 0;
	cursor: pointer;
	padding-right: 35px;
}
.accordion-toggle {
	position: absolute;
	top: 15px;
	right: 15px;
	color: #040517;
}
*/
/* Embed */

.embed-container {
	position: relative;
	padding-top: 56.5%;
}

.embed-content {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: none;
	background: #000;
}

.embed-thumbnail {
	position: relative;
	cursor: pointer;
	background: #000;
}

.embed-thumbnail:hover:after {
	content: "";
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	box-shadow: inset 10px 24px 40px 0px rgba(0, 0, 0, 0.125),
		inset -10px -24px 40px 0px rgba(0, 0, 0, 0.125),
		inset 20px -10px 40px 0px rgba(0, 0, 0, 0.125),
		inset -20px 10px 40px 0px rgba(0, 0, 0, 0.125);
}

.embed-thumbnail .image-thumbnail {
	width: 100%;
	cursor: pointer;
	max-height: 456px;
	object-fit: contain;
}

.embed-thumbnail .image-thumbnail.half {
	max-height: 305px;
}

.embed-thumbnail:hover .image-thumbnail {
	width: 100%;
}

.embed-thumbnail .play-button,
.embed-thumbnail .play-active {
	position: absolute;
	width: 100px;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	pointer-events: none;
}

.embed-thumbnail .play-active {
	display: none;
}

.embed-thumbnail:hover .play-button {
	display: none;
}

.embed-thumbnail:hover .play-active {
	display: block;
}

.embed-link {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	border: 1px solid #ccc;
	cursor: pointer;
	background: #fff;
}

.embed-link:hover {
	border: 1px solid #3d0101;
}

.embed-link img {
	width: 50px;
	object-fit: contain;
}

.embed-link p {
	display: block;
	width: 100%;
	text-align: left;
	margin-left: 15px;
	margin-bottom: 0;
}

.embed-link .btn {
	width: 150px;
	height: 40px;
}

.embed-title {
	position: relative;
	bottom: -40px;
	display: block;
	width: 100%;
	background: #fff;
	padding: 10px 15px;
	margin-bottom: 75px;
	text-align: center;
	border: 1px solid #ccc;
	border-top: none;
}

.modal.pdf.show {
	padding-left: 0 !important;
}

.modal.pdf .modal-header {
	background: #666;
	padding: 5px;
	border-bottom: none;
}
.modal.pdf .modal-header .close {
	padding: 10px 15px 15px;
}
.modal.pdf .modal-dialog {
	height: calc(100% - 5px);
	margin: 5px auto 0;
}
.modal.pdf .modal-content {
	height: calc(100% - 15px);
}
.react-player__preview {
	border: 1px solid #ccc;
}

/* Form */

.form-control {
	color: #040517;
}

.form-check-input {
	margin-top: 0;
}

.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
	color: #3d0101;
	width: 100%;
	font-size: 16px;
	font-weight: 400;
}

.valid-feedback {
	color: #3d0101;
	font-size: 14px;
}

.form-payment p {
	margin-bottom: 0;
}

.form-check-input ~ .form-check-label {
	cursor: pointer;
	display: block;
}

.form-check-input ~ .form-check-label.option-label {
	line-height: 1.8;
}

.input-group-append .form-control {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.input-group-append .btn-primary {
	border: 2px solid #3d0101 !important;
	border-top-right-radius: 50px;
	border-bottom-right-radius: 50px;
	border-left: none !important;
	background: #fff !important;
	cursor: pointer;
	color: #3d0101;
	line-height: 0px;
	margin-left: -29px;
}

.input-group > .form-control:focus {
	outline: none;
	box-shadow: none;
	border-color: #3d0101;
}

.box-light {
	padding: 8px 10px;
	border: 1px solid #ccc;
	background: #fff;
}

.box-options {
	padding: 8px 10px;
	border: 1px solid #ccc;
	border-radius: 4px;
	background: #fff;
}

.box-options .form-check {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.box-options .form-check label {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.box-options-logo {
	height: 40px;
}

.custom-select {
	border-radius: 0;
}

.popup-blocker {
	width: 80px;
	height: 80px;
	position: absolute;
	opacity: 0;
	right: 0px;
	top: 0px;
}

/* Certificate */

.certificate {
	display: none;
	position: relative;
	width: 900px;
	height: 506px;
	margin-top: 30px;
	background-color: #fff;
}
.certificate::after {
	display: block;
	content: "";
	position: absolute;
	top: 0;
	width: 900px;
	height: 506px;
	background: transparent;
}

.certificate-bg {
	width: 900px;
	height: 506px;
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
}

.certificate-logo {
	position: absolute;
	width: 250px;
	top: 45px;
	left: 50%;
	right: 50%;
	transform: translate(-50%, 0);
	max-height: 115px;
	object-fit: contain;
}

.certificate-graduate {
	position: absolute;
	width: 300px;
	top: 90px;
	right: 100px;
}

.certificate-detail {
	position: absolute;
	top: 200px;
	width: calc(100% - 60px);
	left: 50%;
	transform: translate(-50%, 0);
	height: 200px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-align: left;
}

.certificate-text {
	font-family: "Merriweather";
	font-size: 30px;
	text-align: center;
	text-transform: uppercase;
	color: #0171ba;
	margin-bottom: 15px;
}

.certificate-course {
	font-size: 18px;
	font-weight: 500;
	text-align: center;
	line-height: normal;
	color: #0171ba;
	margin-bottom: 30px;
}

.certificate-name {
	font-family: "Merriweather";
	font-size: 36px;
	font-weight: 500;
	text-align: center;
	line-height: normal;
	color: #0171ba;
	max-width: calc(100% - 100px);
	margin: 0 auto;	
	display: -webkit-box;
	overflow: hidden;
	text-overflow: initial;
}

.certificate-date {
	position: absolute;
	width: 100%;
	bottom: 15px;
	font-family: system-ui;
	font-weight: 400;
	text-align: center;
	color: #0171ba;
}

/* Feedback */

.feedback .modal-content {
	border: none;
	border-radius: 8px;
}

.feedback .modal-content textarea {
	border: 2px solid #666;
}

/* Language */

.language-selection {
	padding: 15px;
	border: 3px solid #fff;
	margin-bottom: 15px;
	border-radius: 8px;
	text-align: left;
	cursor: pointer;
	overflow: hidden;
	border: 3px solid #eee;
}

.language-selection:hover {
	border: 3px solid #ccc;
}

.language-selection.active {
	border: 3px solid #e96565;
}

.language-selection .language-image {
	float: left;
	margin-top: 8px;
	margin-right: 15px;
	width: 50px;
	border-radius: 8px;
	border: 1px solid #efefef;
}

.language-selection .language-text {
	float: right;
	max-width: calc(100% - 70px);
}

.language-selection .language-text h4 {
	font-size: 18px;
}

.language-selection .language-text p {
	font-size: 14px;
	margin-bottom: 0;
}

/* Countdown */
p.countdown {
	color: white;
	display: flex;
}

p.countdown > span {
	font-size: 28px;
	display: flex;
	flex-direction: column;
	width: 60px;
}

p.countdown > span > span {
	font-size: 14px;
}

/* Cart */

.cart-item-info {
	display: flex;
	flex-direction: row;
}

.cart-item-info img {
	width: 45px;
	height: 45px;
	object-fit: contain;
	background-color: #fff;
	margin-right: 10px;
}

/* Print */

.visible-print {
	display: none;
}

@media print {
	.alert {
		display: none !important;
	}

	.visible-print {
		display: block !important;
	}

	.hidden-print,
	.footer {
		display: none !important;
	}

	.card-invoice {
		border: none;
		font-family: 'Arial';
	}

	.card-invoice table {
		table-layout: fixed;
	}
	
	.card-invoice tfoot > tr > td {
		background: #fff !important;
		/*border-top: none !important; */
	}

	.card-invoice th:nth-child(1) {
		width: 60% !important;
	}

	.card-invoice th:nth-child(2) {
		width: 10% !important;
	}

	.card-invoice th:nth-child(3) {
		width: 30% !important;
	}

	/* .card-invoice .card-footer .total {
		border-top: 1px solid #000 !important;
	} */

	.pmt-4 {
		margin-top: 4rem;
	}
}

/* Payment */

.qr-logo {
	width: 250px;
	display: block;
	margin: 0 auto;
}

.qr-box {
	display: inline-block;
	padding: 10px;
	box-shadow: 4px 5px 5px 2px rgba(0,0,0,0.41);
	-webkit-box-shadow: 4px 5px 5px 2px rgba(0,0,0,0.41);
	-moz-box-shadow: 4px 5px 5px 2px rgba(0,0,0,0.41);
}

#qris {
	display: block;
	width: 366px;
	height: 500px;
	margin: 15px auto;
	position: relative;
	background-image: url("../img/qris-bg.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

.qr-container {
	position: absolute;
	left: calc(50% - 131px);
	top: 175px;
	border: none;
	box-shadow: none;
}

.qr-order-id, p.qr-order-id {
	margin-bottom: 30px;
	font-weight: 600;
}

.qr-timestamp, p.qr-timestamp {
	font-weight: 600;
	text-align: left;
	margin-top: 30px;
	font-size: 12px;
}

/* Responsive */

@media (max-width: 1200px) {
	/* HD */
	.course-block .card-img-top,
	.library-block .card-img-top {
		height: 150px;
	}
	.course-list img,
	.library-list img {
		height: 115px;
	}
	.purchased .course-list img,
	.purchased .library-list img {
		height: 75px;
	}
}

@media (max-width: 992px) {
	/* Mobile */
	h1 {
		font-size: 34px;
	}
	h4 .label-rounded {
		font-size: 14px;
		margin-bottom: 30px;
	}
	.p-1,
	.p-2,
	.p-3,
	.p-4,
	.p-5 {
		padding: 30px 15px !important;
	}
	.w-40 {
		width: 40% !important;
		min-width: 200px;
	}
	.nav-mobile {
		display: block;
	}
	.nav-desktop {
		display: none;
	}
	.nav-profile label {
		text-align: left;
	}
	.nav-tabs .nav-link {
		min-width: 0;
		padding: 0.5rem 1.2rem;
	}
	.featured-home .agenda {
		margin-top: 30px;
		max-height: 800px;
		overflow: hidden;
	}
	.featured-home .agenda .agenda-item {
		margin-bottom: 15px;
	}
	.profile .bg-light {
		margin-left: 15px;
		margin-right: 15px;
		max-width: calc(100% - 30px);
	}
	.setting .bg-light {
		margin-left: 15px;
		margin-right: 15px;
		max-width: calc(100% - 30px);
	}
	.course-block {
		margin-bottom: 30px;
	}
	.course-block .card-img-top,
	.library-block .card-img-top,
	.course-list img,
	.library-list img {
		height: 110px;
	}
	.purchased .course-list img,
	.purchased .library-list img {
		height: 50px;
	}
	.library-list h5,
	.course-list h5 {
		margin-top: 15px;
	}
	.information .col-md-6:not(:first-child) {
		margin-top: 15px;
	}
	.separator {
		margin: 30px auto;
	}
	.sidebar-tab.sticky-top {
		margin-bottom: 30px;
	}
	.pills-scroll {
		flex-direction: row !important;
		flex-wrap: nowrap;
		overflow: scroll;
	}
	.pills-scroll .nav-item {
		white-space: nowrap;
		margin-bottom: 5px;
	}
	.nav-scroll {
		flex-direction: row !important;
		flex-wrap: nowrap;
		overflow: scroll;
	}
	.nav-scroll .nav-link {
		white-space: nowrap;
		margin-bottom: 5px;
	}
	.nav-main {
		position: sticky;
		top: 0;
		z-index: 1020;
		margin-bottom: 24px;
	}
	.nav-main .navbar-brand img {
		max-width: 125px;
	}
	.nav-second {
		display: none;
	}
	.table-wrapper {
		display: block;
		max-width: 100%;
		overflow-x: auto;
	}
}

@media (max-width: 768px) {
	/* Mobile */
	.carousel-caption {
    right: 0;
    left: 0;
    width: 100%;
	}
	.carousel-caption h3 {
		font-size: 24px;
	}
	.course-block .card-img-top,
	.library-block .card-img-top,
	.course-list img,
	.library-list img {
		height: 450px;
	}
	.purchased .course-list img,
	.purchased .library-list img {
		height: 450px;
	}
	.library-enroll .bg-light.br-8,
	.browse-course .bg-light.br-8,
	.browse-library .bg-light.br-8 {
		margin-left: 15px;
		margin-right: 15px;
	}
	.btn-logout > span {
		font-weight: 500 !important;
	}
	.embed-link {
		flex-direction: column;
	}
	.embed-link p {
		text-align: center;
		margin: 15px 0;
	}
}

@media (max-width: 450px) {
	/* Small Mobile */
	.course-block .card-img-top,
	.library-block .card-img-top,
	.course-list img,
	.library-list img {
		height: 300px;
	}
	.library-usage {
		flex-direction: column;
		border: 1px solid #fff;
	}
	.library-usage .list-group-item {
		border: none;
	}
	.library-usage .list-group-item:first-child {
		border-radius: 8px;
	}
	.library-usage .list-group-item:last-child {
		border-radius: 8px;
	}
}


/* Carousel */

.slider {
	background: url("../img/bg-carousel.png") no-repeat;
	background-position: right;
}

.carousel-item {
	width: 100%;
	height: auto;
}

.carousel-caption img,
.carousel-caption h3,
.carousel-caption p {
	margin-bottom: 20px;
}

.carousel-caption h3 {
	margin-bottom: 20px;
}

.carousel-item img {
	max-width: 420px;
	width: 100%;
}

.carousel .carousel-indicators button {
	
	width: 15px !important;
	height: 15px !important;
	border-radius: 50%;
	background-color: #FFFFFF;
	border: 2px solid #e96565;
}

.carousel .carousel-indicators button.active {
	background-color: #e96565 !important;
}

.carousel-caption img,
.carousel-caption h3,
.carousel-caption p {
	margin-bottom: 20px;
}

.carousel-caption h3 {
	margin-bottom: 20px;
}
